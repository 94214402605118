<template>
  <div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-radio-group
          v-model="fileFormat"
          label="Formato:"
          row
        >
          <v-radio
            label="Shapefile"
            value="SHAPE"
          />
          <v-radio
            label="GeoPerdas CSV"
            value="CSV"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <v-data-table
          id="second-step-exportacao-bdgd-table"
          v-model="selectedItemLocal"
          :headers="headers"
          :items="itemsTable"
          :search.sync="search"
          item-key="entidade"
          :single-select="false"
          show-select
          :loading="loading"
          :hide-default-header="loading"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        >
          <template
            v-slot:top
            v-if="!loading"
          >
            <span id="second-step-search">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                hide-details
                single-line
                class="mx-4 mb-3"
              />
            </span>
          </template>
          <template v-slot:[`item.entidade`]="{ item }">
            {{ item.entidade | toUpperCase }}
          </template>
          <template v-slot:[`item.registros`]="{ item }">
            {{ item.registros | parseNumberToIntegerBR }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Entidade',
        value: 'entidade'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ],
    fileFormat: 'SHAPE'
  }),
  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },
  watch: {
    selectedItem(arrNewVal) {
      this.$emit('stepReleased', arrNewVal.length);
    },
    fileFormat(newVal) {
      this.$emit('fileFormat:changed', newVal);
    }
  }
};
</script>
