<template>
  <v-row class="mx-auto">
    <v-col
      class="pb-0"
      cols="12"
    >
      <v-data-table
        id="first-step-exportacao-bdgd-table"
        v-model="selectedItemLocal"
        :headers="headers"
        :items="items"
        item-key="key"
        :single-select="true"
        show-select
        :loading="loading"
        :hide-default-header="loading"
        :sort-by="['data_registro', 'versao']"
        :sort-desc="['data_registro', 'versao']"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        no-results-text="Nenhum registro correspondente encontrado"
      >
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }}
        </template>
        <template v-slot:[`item.registros`]="{ item }">
          {{ item.registros | parseNumberToIntegerBR }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [],
    headers: [
      {
        text: 'Mês',
        value: 'data_registro'
      },
      {
        text: 'Versão',
        value: 'versao'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ]
  }),

  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },

  watch: {
    itemsTable() {
      this.items = this.itemsTable.map((item) => {
        item.key = item.data_registro + '-' + item.versao;
        return item;
      });
    },
    selectedItem(arrNewVal) {
      this.$emit('stepReleased', arrNewVal.length);
    }
  }
};
</script>
